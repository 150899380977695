import { TranslateService } from '@ngx-translate/core';
import {
  IGenericError,
  IGenericErrorDTO,
} from '../../model/api/http-response.model';
import { HttpParams } from '@angular/common/http';
import {
  ISearchOptions,
  getPageableHttpParams,
} from '../../../share/models/pageable.model';

export abstract class ApiCommonService {
  constructor(protected translate: TranslateService) {}

  getHttpParams(searchOptions?: ISearchOptions) {
    let httpParams = new HttpParams();
    if (searchOptions) {
      httpParams = getPageableHttpParams(searchOptions.pageable);
      if (searchOptions.searchText) {
        httpParams = httpParams.append('searchText', searchOptions.searchText);
      }
    }
    return httpParams;
  }

  protected handleErrorResponse = (
    errorCodeTranslator: (genericError: IGenericError) => string,
    errorsList?: IGenericErrorDTO[] | null
  ): string[] =>
    !errorsList
      ? this.translate.instant('PROPERTY.ERRORS.UNKNOWN')
      : errorsList
          .map((errorElem) => errorElem.errors.map(errorCodeTranslator))
          .flat();
}
