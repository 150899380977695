import { Injectable } from '@angular/core';
/* eslint-disable @typescript-eslint/no-explicit-any */

interface CacheEntry {
  value: any;
  expiry: number;
}

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private cache: Map<string, CacheEntry> = new Map();

  set(key: string, value: any, ttlSecs: number): void {
    const expiry = Date.now() + ttlSecs * 1000;
    const cacheEntry: CacheEntry = { value, expiry };
    this.cache.set(key, cacheEntry);
    this.autoRemove(key, ttlSecs);
  }

  get(key: string): any {
    const cacheEntry = this.cache.get(key);
    if (!cacheEntry) {
      return null;
    }
    if (Date.now() > cacheEntry.expiry) {
      this.cache.delete(key);
      return null;
    }
    return cacheEntry.value;
  }

  private autoRemove(key: string, ttl: number): void {
    setTimeout(() => {
      const cacheEntry = this.cache.get(key);
      if (cacheEntry && Date.now() > cacheEntry.expiry) {
        this.cache.delete(key);
      }
    }, ttl * 1000);
  }
}
