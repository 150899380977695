import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  tap,
  catchError,
  throwError,
  map,
  retryWhen,
  take,
  timeout,
  of,
} from 'rxjs';
import {
  LoaderService,
  setLoaderFalse,
} from '../../../../core/services/loader.service';
import { SnackBarService } from '../../../../share/services/utils/snack-bar.service';
import { IApartmentDTO, IImageDTO } from '../../model/apartment.model';
import { ISearchOptions } from '../../../../share/models/pageable.model';
import {
  IGenericArrayResponseDTO,
  IGenericError,
  IGenericResponseDTO,
  IHttpErrorResponse,
} from '../../../../core/model/api/http-response.model';
import { CacheService } from '../../../../core/services/cache/cache.service';
import { ApiCommonService } from '../../../../core/services/api/api-common.service';

@Injectable({
  providedIn: 'root',
})
export class ApartmentApiService extends ApiCommonService {
  constructor(
    translate: TranslateService,
    private http: HttpClient,
    private loader: LoaderService,
    private snackBar: SnackBarService,
    private cacheService: CacheService
  ) {
    super(translate);
  }

  createApartment(apartmentDTO: IApartmentDTO): Observable<IApartmentDTO> {
    this.loader.setLoader(true);
    return this.http
      .post<IGenericResponseDTO<IApartmentDTO>>('/apartments', apartmentDTO)
      .pipe(
        setLoaderFalse(this.loader),
        map((apartmentResponse) => apartmentResponse.response as IApartmentDTO),
        catchError((errorData: IHttpErrorResponse) => {
          this.snackBar.openSnackbarError({
            label: this.handleErrorResponse(this.getErrorMessage)[0],
          });
          return throwError(() => errorData);
        })
      );
  }

  updateApartment(
    apartmentId: number,
    apartmentDTO: IApartmentDTO
  ): Observable<IApartmentDTO> {
    this.loader.setLoader(true);
    return this.http
      .put<
        IGenericResponseDTO<IApartmentDTO>
      >(`/apartments/${apartmentId}`, apartmentDTO)
      .pipe(
        setLoaderFalse(this.loader),
        map((apartmentResponse) => apartmentResponse.response as IApartmentDTO),
        catchError((errorData: IHttpErrorResponse) => {
          this.snackBar.openSnackbarError({
            label: this.handleErrorResponse(this.getErrorMessage)[0],
          });
          return throwError(() => errorData);
        })
      );
  }

  getApartment = (
    apartmentId: number,
    cache?: number
  ): Observable<IApartmentDTO> =>
    this._getApartments([apartmentId], cache).pipe(
      map((apartments) => apartments[0])
    );

  getApartments = (
    apartmentIds: number[],
    cache?: number
  ): Observable<IApartmentDTO[]> => this._getApartments(apartmentIds, cache);

  private _getApartments(
    apartmentIds: number[],
    cache?: number
  ): Observable<IApartmentDTO[]> {
    this.loader.setLoader(true);
    const url = `/apartments/byIds/${apartmentIds}`;
    const cacheResponse = this.cacheService.get(url);
    if (cacheResponse != null) {
      setLoaderFalse(this.loader);
      return of(cacheResponse);
    }
    return this.http.get<IGenericArrayResponseDTO<IApartmentDTO>>(url).pipe(
      setLoaderFalse(this.loader),
      map((apartmentsResponse) => {
        if (cache) {
          this.cacheService.set(url, apartmentsResponse.response, cache);
        }
        return apartmentsResponse.response as IApartmentDTO[];
      }),
      catchError((errorData: IHttpErrorResponse) => {
        this.snackBar.openSnackbarError({
          label: this.handleErrorResponse(this.getErrorMessage)[0],
        });
        return throwError(() => errorData);
      })
    );
  }

  searchApartments(searchOptions?: ISearchOptions) {
    this.loader.setLoader(true);
    return this.http
      .get<IGenericResponseDTO<IApartmentDTO[]>>(`/apartments/search`, {
        params: this.getHttpParams(searchOptions),
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<IGenericResponseDTO<IApartmentDTO[]>>) => {
          return {
            apartments: response.body?.response,
            numberOfPages: parseInt(
              response.headers.get('Page-Count') ?? '0',
              10
            ),
          };
        }),
        catchError((errorData: IHttpErrorResponse) => {
          this.snackBar.openSnackbarError({
            label: this.handleErrorResponse(this.getErrorMessage)[0],
          });
          return throwError(() => errorData);
        }),
        setLoaderFalse(this.loader)
      );
  }

  getUserApartments(userId: string, searchOptions?: ISearchOptions) {
    this.loader.setLoader(true);
    return this.http
      .get<IGenericResponseDTO<IApartmentDTO[]>>(
        `/apartments/admin/${userId}`,
        {
          params: this.getHttpParams(searchOptions),
          observe: 'response',
        }
      )
      .pipe(
        map((response: HttpResponse<IGenericResponseDTO<IApartmentDTO[]>>) => {
          return {
            apartments: response.body?.response,
            numberOfPages: parseInt(
              response.headers.get('Page-Count') ?? '0',
              10
            ),
          };
        }),
        catchError((errorData: IHttpErrorResponse) => {
          this.snackBar.openSnackbarError({
            label: this.handleErrorResponse(this.getErrorMessage)[0],
          });
          return throwError(() => errorData);
        }),
        setLoaderFalse(this.loader)
      );
  }

  deleteApartament(apartmentId: string) {
    this.loader.setLoader(true);
    return this.http
      .delete<IGenericResponseDTO<IApartmentDTO>>(`/apartments/${apartmentId}`)
      .pipe(
        setLoaderFalse(this.loader),
        tap(() => this.snackBar.openSnackbarSuccess()),
        catchError((errorData: IHttpErrorResponse) => {
          this.snackBar.openSnackbarError({
            label: this.handleErrorResponse(this.getErrorMessage)[0],
          });
          return throwError(() => errorData);
        })
      );
  }

  uploadPicture(apartmentId: number, imageUploadDTO: IImageDTO) {
    this.loader.setLoader(true);
    return this.http
      .post<IImageDTO>(`/apartments/${apartmentId}/image`, imageUploadDTO)
      .pipe(
        setLoaderFalse(this.loader),
        timeout(10000),
        retryWhen((errors) =>
          errors.pipe(
            take(3),
            catchError((err) => {
              this.snackBar.openSnackbarError({
                label: this.translate.instant(
                  'PROPERTY.IMAGES.FAIL_SEVERAL_ATTEMPTS'
                ),
              });
              return throwError(() => err);
            })
          )
        ),
        catchError((error) => {
          if (error.name === 'TimeoutError') {
            this.snackBar.openSnackbarError({
              label: this.translate.instant('PROPERTY.IMAGES.TIMEOUT_ERROR'),
            });
          } else {
            this.snackBar.openSnackbarError({
              label: error.error.title ?? '',
            });
          }
          return throwError(() => error);
        })
      );
  }

  getFile(fileRepositoryId: number) {
    return this.http
      .get<IImageDTO>(`/file-repository/${fileRepositoryId}`)
      .pipe(
        catchError((error: IHttpErrorResponse) => {
          this.snackBar.openSnackbarError({
            label: error.error.title ?? '',
          });
          return throwError(() => error);
        })
      );
  }

  private getErrorMessage = (error: IGenericError) => {
    switch (error.code) {
      case 'apartment.error.wrongTimeDifferenceBetweenCheckInAndCheckOut':
        return this.translate.instant('PROPERTY.ERRORS.CHECK_IN_TIME_APART');
      case 'apartment.error.apartmentHasFutureReservations':
        return this.translate.instant('PROPERTY.ERRORS.FUTURE_RESERVATIONS');
      default:
        return this.translate.instant('PROPERTY.ERRORS.UNKNOWN');
    }
  };
}
